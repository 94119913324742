section.editPage {
  background:
    url("../../public/homescreen.jpg") top center / 100vw 60rem no-repeat,
    url("../../public/part2.jpg") center center / 100vw auto no-repeat,
    url("../../public/doc2.jpg") bottom center / 100vw auto no-repeat;
}
button.toAdminAuth {
  color: white;
  background-color: #739666;
  font-size: 1.4rem;
  position: relative;
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 0.8rem 1.7rem 0.8rem 1.7rem;
}
/* #516d4e */
/* #f4fae8 */
div.previewEdit {
  background-color: #f4fae8;
  width: 92vw;
  margin: 0 auto;
}
div.textTop {
  background-color: #516d4e;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.textInfoEditBox {
  position: relative;
}
div.topBtm {
  margin-left: 4vw;
}
button.editTextBtn {
  color: white;
  background-color: #739666;
  font-size: 1.4rem;
  position: relative;
  padding: 0.5rem 1.3rem 0.5rem 1.3rem;
}
h1.photoTitle {
  background-color: #516d4e;
  color: white;
  padding: 1rem 0 1rem 0;
}
h1.artEditTitle {
  background-color: #516d4e;
  color: white;
  padding: 1rem 0 1rem 0;
}
div.textBtm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
