html::-webkit-scrollbar {
  display: none;
}
body::-webkit-scrollbar {
  display: none;
}

:root {
  --font-color: #2d4228;
  --bloom-title: 2.2rem;
  --des-size: 1.5rem;
  --label-size: 1rem;
  --top-bar-height: 80px;
  --art-shift: 15vw;
  --letter-size: 1.5rem;
  --plant-size: calc(var(--letter-size) - 0.6rem);
  --dbImg-width: 15.5vw;
  --dbImg-height: 8vw;
}

.notFound {
  width: 100vw;
  height: 100vh;
  background-color: #739666;
  color: white;
}

.notFoundBtn {
  color: white;
  font-size: 20px;

  position: fixed;
  top: 56%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: underline;
}

.notFoundTitle {
  font-size: 50px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section.aboutUs {
  border: none;
  position: relative;
  margin: 0;
  background:
    url("../public/idk.jpg") top / 100vw 60rem no-repeat,
    url("../public/4.jpg") center / 100vw auto no-repeat,
    url("../public/homescreen.jpg") bottom / 100vw auto no-repeat;
}

.aboutUsContent {
  position: relative;
  margin: 0 auto;
  background-color: #f4fae8;
  height: 125vw;
  width: 94vw;
  z-index: 2;
}

.aboutUsContent h1 {
  position: relative;
  top: 1.4rem;
  font-size: 2rem;
  margin: 0;
  color: #2d4228;
}

.caption {
  font-family: poppins sans-serif;
  color: #2d4228;
  top: 21.2vw;
  font-size: 0.6rem;
  position: absolute;
  left: 60.7vw;
  z-index: 5;
  font-weight: bold;
}

.flowerIcon {
  width: 17vw;
  height: 17vw;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1.2vw;
  top: 9.46vw;
  z-index: 4;
}

.flowerBottom {
  top: 23.11vw;
  width: 25.57vw;
  position: absolute;
  left: 61vw;
  z-index: 2;
}

.birdPic {
  width: 17.67vw;
  position: absolute;
  top: 12.67vw;
  left: 1.73vw;
  z-index: 1;
}

.greenBox {
  width: 17.67vw;
  height: 28.44vw;
  background-color: #a8c082;
  position: absolute;
  top: 14.67vw;
  left: 4.08vw;
  z-index: 0;
}

.clubLabel {
  position: absolute;
  font-size: 1.8rem;
  text-decoration: underline;
  color: #2d4228;
  left: 6.22vw;
  top: 4.56vw;
  margin: 0;
  padding: 0;
  font-family: poppins sans-serif;
}

.clubContent {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  position: absolute;
  overflow: auto;
  width: 64.04vw;
  right: 1.46vw;
  height: 55.09vw;
  top: 1.7vw;
  font-size: 19px;
  color: #2d4228;
  font-family: poppins sans-serif;
}

.webLabel {
  position: absolute;
  font-size: 1.8rem;
  text-decoration: underline;
  color: #2d4228;
  top: 2rem;
  margin: 0;
  padding: 0;
  left: 2rem;
  font-family: poppins sans-serif;
}

.webContent {
  left: 2rem;
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  word-wrap: break-word;

  position: absolute;
  overflow: auto;
  width: 59.15vw;
  font-size: 18px;
  color: #2d4228;
  top: 6.94vw;
  font-family: poppins sans-serif;
}

.clubContent p::first-line {
  text-indent: 2rem;
}

.aboutClubBox {
  margin: 0;
  padding: 0;
  display: inline-block;
  height: 40.09vw;
  border-top: solid #516d4e 5px;

  align-items: center;
  width: 90vw;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 3.17vw;
}

.aboutWebBox {
  margin: 0;
  padding: 0;
  display: inline-block;
  height: 36.09vw;
  border-top: solid #516d4e 5px;
  align-items: center;
  width: 90vw;
  position: relative;
  font-size: 18px;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 12vw;
}

.contactUs {
  margin: 0;
  padding: 0;
  display: inline-block;
  height: auto;
  border-top: solid #516d4e 5px;
  top: 200vw;
  align-items: center;
  width: 90vw;
  position: relative;
  left: 50%;
  margin-top: 70px;
  transform: translate(-50%, 0%);
  top: 8vw;
}

.subLabel1 {
  font-family: poppins sans-serif;
  color: #2d4228;
  left: 2rem;
  position: absolute;
  top: 18.28vw;
  text-decoration: underline;
}

.subSubLabel1 {
  font-family: poppins sans-serif;
  color: #2d4228;
  left: 0rem;
  text-align: center;
  position: absolute;
  top: 20.8vw;
}

.subLabel2 {
  font-family: poppins sans-serif;
  color: #2d4228;
  position: absolute;
  top: 18.28vw;
  text-decoration: underline;
  left: 21.15vw;
}

.subSubLabel2 {
  font-family: poppins sans-serif;
  color: #2d4228;
  font-size: 1rem;
  position: absolute;
  text-align: center;
  top: 20.8vw;
  left: 21.15vw;
}

.subLabel3 {
  font-family: poppins sans-serif;
  color: #2d4228;
  font-size: 1rem;
  position: absolute;
  top: 18.28vw;
  text-decoration: underline;
  left: 31.65vw;
}

.subSubLabel3 {
  font-family: poppins sans-serif;
  color: #2d4228;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  top: 20.8vw;
  left: 35.5vw;
}

.subLabel4 {
  font-family: poppins sans-serif;
  color: #2d4228;
  font-size: 1rem;
  left: 2rem;
  position: absolute;
  top: 26.28vw;
  text-decoration: underline;
}

.subSubLabel4 {
  font-family: poppins sans-serif;
  color: #2d4228;
  font-size: 1rem;
  left: 2rem;
  position: absolute;
  top: 28.8vw;
}

.whiteFlower {
  width: 27.09vw;
  position: absolute;
  top: 9.4vw;
  left: 2.66vw;
  z-index: 1;
}

.greenBox2 {
  left: 1vw;
  width: 27.09vw;
  height: 15.2vw;
  z-index: 0;
  background-color: #a8c082;
  top: 7.4vw;
  position: absolute;
}

.contactUsTitle {
  font-family: poppins sans-serif;
  position: absolute;
  font-size: 1.8rem;
  text-decoration: underline;
  color: #2d4228;
  left: 0;
  margin: 0;
  padding: 0;
  top: 2rem;
  left: 2rem;
}

.cssHell {
  top: 23.2vw;
  width: 54.49vw;
  position: absolute;
  left: 33.252vw;
}

.contactLabel1 {
  font-family: poppins sans-serif;
  color: #2d4228;
  position: absolute;
  left: 38.82vw;
  top: 2.43vw;
}

.contactInfo1 {
  font-family: poppins sans-serif;
  color: #2d4228;
  position: absolute;
  left: 38.82vw;
  top: 4vw;
  left: 54vw;
}

.contactLabel2 {
  font-family: poppins sans-serif;
  color: #2d4228;
  position: absolute;
  left: 38.82vw;
  top: 7.77vw;
}

.contactInfo2 {
  font-family: poppins sans-serif;
  color: #2d4228;
  position: absolute;
  left: 38.82vw;
  top: 9.2vw;
  left: 54vw;
}

.contactLabel3 {
  font-family: poppins sans-serif;
  color: #2d4228;
  position: absolute;
  left: 38.82vw;
  top: 14.54vw;
}

.contactInfo3 {
  font-family: poppins sans-serif;
  color: #2d4228;
  position: absolute;
  top: 16vw;
  text-align: center;
  left: 54vw;
}

.bottomBar {
  color: #516d4e;
  width: 100vw;
  position: absolute;
  z-index: 1;
  height: 4rem;
  background-color: #516d4e;
}

@media screen and (max-width: 1500px) {
  :root {
    --bloom-title: 1.75rem !important;
    --top-bar-height: 60px !important;
    --des-size: 1.2rem !important;
    --label-size: 0.9rem !important;
  }
}

.aboutUsLang {
  position: absolute;
  top: 1.4rem;
  display: flex;
  flex-direction: row;
  right: 2rem;
}

.aboutUsLangButton {
  color: #2d4228;
  font-weight: bold;
}
