section.creationPart1 {
  background:
    url("../../public/homescreen.jpg") top center / 100vw 60rem no-repeat,
    url("../../public/doc3.jpg") center center / 100vw auto no-repeat,
    url("../../public/doc2.jpg") bottom center / 100vw auto no-repeat;
  border: none;
  position: relative;
  margin: 0;
}
div.subPageLinks {
  display: flex;
  flex-direction: row;
  width: 92vw;
  height: 65px;
  position: relative;
  z-index: 1000;
  background-color: #f4fae8;
  margin: 0 auto;
}
button.subPageButton {
  color: #2d4228;
  width: 15vw;
  font-weight: 600;
}
button.active {
  color: #f4fae8;
  background-color: #516d4e;
}
section.loadingCreation {
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #63895f;
}
