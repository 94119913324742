body.glossary {
  color: #ffffff;
  background: url("../../public/homescreen.jpg") no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-position: center;
  overflow-x: hidden;
  display: flex;
  height: 100vh;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
h.title {
  position: relative;
  text-align: left;
  left: 4vw;
  padding-right: 10vw;
  top: 3.4vh;
  border-right: 6px solid white;
  font-size: 2.5rem;
  font-weight: 600;
}
div.sec {
  background-color: #f4fae8;
  text-align: center;
  position: relative;
  margin: 0 auto;
  margin-top: 0.5rem;
  width: 85vw;
  flex: 1;
  color: black;
  align-items: center;
  justify-content: center;
}
div.dbBlock {
  width: 93vw;
  position: relative;
  background-color: #516d4e;
  padding: 0;
  height: 6vh;
}
#topBar {
  display: flex;
  position: relative;
}
h1 {
  text-align: center;
}
div.gTop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}
.back {
  position: absolute;
  left: 4.5rem;
  font-size: var(--letter-size);
  font-weight: 600;
  color: black;
}
h1.gh1 {
  position: relative;
  font-size: var(--letter-size);
  font-weight: 600;
}
section.letters {
  display: grid;
  position: relative;
  padding-left: 4rem;
  gap: 2rem;
  padding-right: 4rem;
  grid-template-columns: repeat(3, 1fr);
}
div.letters1 {
  display: flex;
  position: relative;
  flex-direction: column;
}
div.letters2 {
  display: flex;
  position: relative;
  flex-direction: column;
}
div.letters3 {
  display: flex;
  position: relative;
  flex-direction: column;
}

div.greenTop {
  background-color: #516d4e;
  height: 1.9rem;
  width: 100%;
}

.fullList a {
  color: black;
}

.fullList p {
  margin: 0;
}

.fullList {
  text-align: left;
  font-size: 1.2rem;
  font-weight: 700;
  width: fit-content;
  padding: 1rem;
  padding-right: 2rem;
  position: relative;
  align-self: flex-end;
  margin-right: 2rem;
  margin-bottom: 2.5rem;
  background-color: #cbeb8c;
}
