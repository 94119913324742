.img1 {
  max-width: 20vw;
  max-height: 20vh;
  position: relative;
  left: 2vw;
}

.btn1 {
  right: 5vw;
  bottom: 2vw;
  color: white;
  background-color: #739666;
  padding: 0.5vw 1vw;
  position: absolute;
}

.btn2 {
  color: white;
  background-color: #739666;
  padding: 0.5vw 1vw;
  position: absolute;
  right: 40rem;
}

section.upload {
  min-height: 100vh;
  background:
    url("../../public/homescreen.jpg") top center / 100vw 60rem no-repeat,
    url("../../public/part2.jpg") center center / 100vw auto no-repeat,
    url("../../public/doc2.jpg") bottom center / 100vw auto no-repeat;
  padding-bottom: 1rem;
}

section.uploadBody {
  background-color: #f4fae8;
  position: relative;
  padding-bottom: 8rem;
  width: 92vw;
  margin: 0 auto;
}
div.topBtns {
  display: flex;
  border-bottom: 3px solid #63895f;
}
div.topBtns button {
  min-width: 17vw;
  padding: 0.8rem;
  color: #2d4228;
  font-weight: 500;
}
.focused {
  background-color: #63895f;
  color: white !important;
  text-decoration: none;
}
button.creation {
  width: 23vw;
}

section.loadingBG {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #63895f;
}

.dots-container {
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
.dots {
  animation: bounce 1.5s infinite linear;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  text-align: center;
  width: 20px;
}

.dots:nth-child(1) {
  animation-delay: 0.2s;
}
.dots:nth-child(2) {
  animation-delay: 0.4s;
}
.dots:nth-child(3) {
  animation-delay: 0.6s;
}
.dots:nth-child(4) {
  animation-delay: 0.8s;
}
.dots:nth-child(5) {
  animation-delay: 1s;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-15px);
  }
  30% {
    transform: translateY(0);
  }
}
