div.authBody {
  display: flex;
  margin: 0 4vw 0 4vw;
  flex-direction: column;
  gap: 1rem;
  justify-content: left;
}
h1.titleSections {
  margin: 0.7rem 0 0.7rem 0;
  color: #2d4228;
  font-weight: 400;
  text-align: left;
  font-size: 1.2rem;
}
h1.titleAdmin {
  color: #2d4228;
  position: relative;
  text-align: inherit;
  margin-bottom: 0;
}
div.authBox {
  border: 2px solid #2d4228;
  position: relative;
}
div.authBtm {
  text-align: left;
  padding-left: 2rem;
}
h1.plantNameTitle {
  color: #2d4228;
  text-align: left;
  padding-left: 1.8rem;
  font-size: 1.6rem;
}
li.Chi {
  font-size: 1rem;
  position: relative;
  font-weight: 700;
  padding: 0.2rem;
  left: 0.7vw;
}
button.decisionBtn {
  background-color: #739667;
  color: white;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin: 1rem;
  font-size: 1.3rem;
  cursor: pointer;
}
div.shiftIcons {
  position: absolute;
  bottom: 0.5rem;
  right: 0;
}
img.authPic {
  height: 20vw;
  width: 36vw;
  object-fit: cover;
  position: relative;
}
img.authArt {
  position: relative;
  height: 26.5vw;
  width: 20vw;
  object-fit: cover;
}
h1.none {
  color: #2d4228;
  font-weight: 400;
}
div.creationAuthBox {
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
}
div.picBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.creationAuth {
  display: flex;
  flex-direction: column;
}
h1.authCreationTtl {
  color: white;
  padding: 0.4rem 1.5rem 0.4rem 1.5rem;
  font-weight: 400;
  background-color: #739667;
  font-size: 1.2rem;
}
