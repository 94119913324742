h1.homeTitle {
  color: #2d4228;
  font-size: 1.8rem;
  text-align: left;
  position: relative;
  left: 5vw;
}
div.homeEntryBox {
  border: 2px solid #516d4e;
  width: 50vw;
  margin-left: 5vw;
}

.card {
  margin: 0 5vw;
  padding: 2rem;
  background: #ffffff;
  border: 2px solid #516d4e;
  border-radius: 4px;
  margin-bottom: 2rem;
}

.p-dropdown {
  border: 2px solid #516d4e !important;
  border-radius: 0 !important;
}

.p-button {
  border-radius: 0 !important;
}

.p-button.p-button-success {
  background: #516d4e !important;
  border-color: #516d4e !important;
}

.p-button.p-button-danger {
  background: #dc3545 !important;
  border-color: #dc3545 !important;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.col-12 {
  padding: 0.5rem;
}

.picture-grid,
.art-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  max-height: 500px;
  overflow-y: auto;
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.picture-item,
.art-item {
  border: 2px solid #dee2e6;
  border-radius: 0;
  padding: 0.5rem;
  transition: all 0.3s ease;
  position: relative;
}

.picture-item img,
.art-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
}

.picture-item.selected,
.art-item.selected {
  border-color: #516d4e;
  background-color: rgba(81, 109, 78, 0.15);
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(81, 109, 78, 0.2);
}

.picture-item.selected::after,
.art-item.selected::after {
  content: "✓";
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #516d4e;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.picture-item:hover,
.art-item:hover {
  border-color: #516d4e;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.featured-entry {
  border: 2px solid #516d4e;
  border-radius: 0;
  padding: 1rem;
  margin-bottom: 1rem;
}

.p-dialog-maximized {
  .p-dialog-content {
    padding: 0;
  }
}
