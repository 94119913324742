body.loginbd {
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../public/intro.jpg") no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
.form-box-login {
  position: fixed;
  width: 32vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-box-login::after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  position: absolute;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
h2.titleLogin {
  font-size: 2.5rem;
  bottom: 4vh;
  font-weight: 500;
  position: relative;
  color: #fff;
  text-align: center;
}
.inputboxLogin {
  position: relative;
  margin: 30px 0;
  bottom: 2vh;
  font-size: 1.1rem;
  width: 18rem;
  border-bottom: 2px solid #fff;
}
.inputboxLogin label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}
input:focus ~ label,
input:valid ~ label {
  top: -5px;
}
.inputboxLogin input {
  width: 100%;
  height: 50px;
  font-size: 1.2rem;
  background: transparent;
  border: none;
  outline: none;
  padding: 0 35px 0 5px;
  color: #fff;
}
.inputboxLogin ion-icon {
  position: absolute;
  right: 8px;
  color: #fff;
  top: 20px;
}
.forget {
  margin: -15px 0 15px;
  font-size: 0.9em;
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.forget label input {
  margin-right: 3px;
}
.forget label a {
  color: #fff;
  text-decoration: none;
}
.forget label a:hover {
  text-decoration: underline;
}
button.loginBtn {
  width: 100%;
  background-color: #739667;
  color: white;
  height: 40px;
  position: relative;
  border-radius: 40px;
  bottom: 2vh;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
}
.login {
  font-size: 0.95rem;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}
.login p a {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
}
.login p a:hover {
  text-decoration: underline;
}
h1 {
  text-align: center;
}
div.loading {
  position: relative;
  display: flex;
  color: white;
  text-align: center;
  left: 4rem;
}
div.err {
  position: relative;
  display: flex;
  color: white;
  justify-content: center;
}
