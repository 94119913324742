#searchBar {
  top: 1.2vw;
  left: 0vw;
  position: relative;
  height: 4.2vw;
}

.searchBar {
  display: flex;
  padding: 1rem;
}

.db1Icon {
  position: relative;
  left: 18vw;
}

.searchBar {
  position: relative;
  flex: 1;
  border: none;
  font-size: 1.5rem;
  outline: none;
  background-color: transparent;
  color: black;
  bottom: 0.1rem;
  left: 2rem;
}
.searchBar::placeholder {
  font-size: 1.3rem;
}

.searchBtn {
  position: relative;
  background-color: transparent;
  border: none;
  font-size: 34px;
  cursor: pointer;
  outline: none;
  top: 0.2rem;
  right: 1.5rem;
}
.searchtitle {
  position: relative;
  left: 3rem;
  text-align: left;
  font-weight: 580;
}

.submitForm {
  width: 70vw;

  position: relative;
  top: 0.2vw;
}
.topbarInfoDB {
  padding-top: 0rem;
  background-color: #516d4e;
  padding-bottom: 0rem;
  position: relative;
  width: 93vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  height: 9rem;
}

h3.db1Title {
  font-style: italic;
  position: relative;
  left: 0.5vw;
  margin: 0;
}

.db1T {
  position: relative;
  margin: 0;
}

.db1name {
  position: relative;
  margin: 0;
  left: 1vw;
}

div.ttl {
  display: flex;
  color: white;
  padding-bottom: 1rem;
  font-size: 1.45rem;
  align-items: center;
  position: relative;
  left: 2.5vw;
}
img.databaseImg {
  width: var(--dbImg-width);
  height: var(--dbImg-height);
  cursor: pointer;
  object-fit: cover;
}
div.dbImgs {
  display: grid;
  gap: 26px;
  grid-template-columns: repeat(4, 1fr);
}
div.results {
  position: absolute;
  top: 7vh;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  right: 16.5vw;
}
h2.resulth2 {
  font-weight: 200;
  font-size: 1rem;
  bottom: 0.3vh;
  position: relative;
}

.db1SearchButton {
  position: relative;
  bottom: 0.1vh;
  right: 19vw;
  color: black;
}
.db1SearchButton:hover {
  color: #516d4e;
}
div.top {
  display: flex;
  align-items: top;
  justify-content: center;
  gap: 6%;
}
div.info {
  position: relative;
  width: 50vw;
  text-align: left;
}
div.arts {
  position: relative;
  text-align: left;
}
.shiftIcon {
  position: relative;
}
h3.artTitle {
  font-size: 1.7rem;
  position: relative;
  left: 5vw;
}
h3.titleMessage {
  font-size: 1.7rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}
li.Eng {
  font-size: 1rem;
  position: relative;
  font-weight: 400;
  padding: 0.2rem;
  left: 0.7vw;
}
li.CN {
  font-size: 1rem;
  position: relative;
  font-weight: 400;
  padding: 0.2rem;
  left: 0.7vw;
}
img.artPic {
  max-width: 20vw;
  cursor: pointer;
}
h3.editor {
  font-weight: 400;
  position: relative;
  text-align: left;
  left: 8.5vw;
  bottom: 2vh;
}
div.hline11 {
  position: relative;
  height: 0.5vh;
  background-color: #516d4e;
  width: 84vw;
  margin-top: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
div.Bar {
  position: relative;
  justify-content: center;
  display: flex;
}
h3.btTitle {
  position: relative;
  right: 28vw;
  font-size: 1.5rem;
}
button.expandBtn {
  font-weight: 600;
  position: relative;
  left: 25vw;
}
button.expandBtn:hover {
  text-decoration: underline;
}
h1.seasonTitle {
  position: relative;
  right: 34.5vw;
  font-weight: 600;
  font-size: 1.5rem;
}

#buttonDb11 {
  background-color: white;
}

div.fit {
  width: var(--dbImg-width);
  height: var(--dbImg-height);
  position: relative;
  background-color: rgba(119, 119, 119, 0.2);
}
.databaseImg,
.fit {
  display: inline-block;
}
div.hline1 {
  position: relative;
  top: 15vh;
  height: 0.5vh;
  background-color: #516d4e;
  width: 85vw;
  margin: 0 auto;
}
button.prev1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: right;
}
button.next1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
}
div.artAlt {
  width: 20vw;
  height: 40vh;
  background-color: rgba(185, 185, 185, 0.468);
}
div.artPicContainer {
  display: flex;
}

.searchResultsButton1 {
  text-align: left;
  margin-bottom: 0.5vh;
  width: 62vw;
  height: 3vw;
  background-color: white;
}
.db1Infos {
  word-wrap: break-word;
}

.zoomBox {
  position: fixed;
  display: inline-block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #f4fae8;
}

.zoomPic {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 70vw;
  max-height: 70vh;
}

.xButton {
  color: white;
  position: absolute;
  right: 12vw;
  top: 3vw;
  text-decoration: underline;
  color: #2d4228;
  font-size: 1.7vw;
}

button.featurePicBtn {
  cursor: pointer;
  color: white;
  text-decoration: underline;
}
div.featureInfo {
  all: initial;
  display: flex;
  position: fixed;
  right: 4rem;
  bottom: 3rem;
  background-color: #739666;
  color: white;
  font-size: 1.5rem;
  font-family: "Noto Sans TC", sans-serif;
  padding: 0.7rem 1rem 0.7rem 1rem;
  z-index: 10000;
}
div.featureInfo p {
  margin: 0;
}
button.editBtn {
  position: absolute;
  right: 8vw;
  top: 0;
  margin-top: 1.5em;
  color: white;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: #63895f;
  font-size: 1.2rem;
}

div.seasonContainer {
  display: grid;
  position: relative;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
}

section.loadingCreation {
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #63895f;
}

.dotsContainer {
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
.dots {
  animation: bounce 1.5s infinite linear;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  text-align: center;
  width: 20px;
}

.dots:nth-child(1) {
  animation-delay: 0.2s;
}
.dots:nth-child(2) {
  animation-delay: 0.4s;
}
.dots:nth-child(3) {
  animation-delay: 0.6s;
}
.dots:nth-child(4) {
  animation-delay: 0.8s;
}
.dots:nth-child(5) {
  animation-delay: 1s;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  15% {
    transform: translateY(-15px);
  }
  30% {
    transform: translateY(0);
  }
}

div.artLabel {
  position: absolute;
  top: 90%;
  left: 90%;
  display: flex;
  width: max-content;
  flex-direction: column;
  gap: 2px;
  max-width: 9rem;
  padding: 0.4rem;
  color: white;
  background-color: #84a379;
}

div.artLabel p {
  margin: 0;
  font-weight: 500;
  font-size: calc(var(--label-size) - 0.1rem);
}
.widen {
  width: 80vw !important;
}
